<template>
  <div>
    <div class="left-body">
      <div class="pr-1">
        <v-select
          v-model="keyWord"
          label="GuestName"
          :options="listBDC"
          :reduce="(GuestName) => GuestName.BDC"
          :clearable="true"
          @input="printDetail(keyWord)"
          placeholder="Tìm kiếm tên, BBC"
        />
        <div style="overflow-y: auto; height: 760px;">
          <div>
            <b-button
              class="print"
              variant="primary"
              @click="printAll()"
              style="margin-top: 10px"
              >{{ $t("golf_Invoice_all") }}</b-button
            >
          </div>
          <div>
            <b-button
              class="print"
              variant="primary"
              @click="printSum()"
              style="margin-top: 10px"
              >{{ $t("golf_Invoice_total") }}</b-button
            >
          </div>
          <div v-for="(item, index) in listBDC" :key="index">
            <b-button
              class="print"
              variant="primary"
              @click="printDetail(item.BDC)"
              style="margin-top: 10px; width: 80%"
            >
              <div style="padding: 5px 0 5px 0">
                {{ item.BDC }} - {{ item.GuestName }}
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div id="iv02">loading...</div>
  </div>
</template>

<script>
import { API_RIV02, REPORT_URL } from "@/api/constant";
import { bookingDrivingRange } from '@/api/booking-driving-range'
import { commonServices } from '@/api/common-services'
import jQuery from "jquery";
import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
window.$ = jQuery;
export default {
  name: "ReportViewer",
  data() {
    return {
      msg: "Print invoice",
      PaymentDrId: null,
      obj: null,
      objdeltai: null,
      objdeltai2: null,
      objdeltai3: null,
      objdeltai4: null,
      json: null,
      isSelectBDC: false,
      listBDC: [],
      response: null,
      keyWord: null,
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pPaymentDrId: null,
      pIndex: 0,
      pIsGetAll: true,
      pUrl: null,
      fileName: null,
    };
  },
  created() {
    this.api_RIV01();
    this.getParam()
  },
  watch: {
    pUrl() {
      this.getInvoice()
    }
  },
  methods: {
    print() {
      this.json = this.obj;
      this.resetInvoice()
    },
    printAll() {
      this.pIsGetAll = true
      this.resetInvoice()
    },
    printSum() {
      this.pIndex = 0
      this.pIsGetAll = false
      this.resetInvoice()
    },
    printDetail(BDCselect) {
      for (let i = 1; i < this.response.Data.Invoice.length; i++) {
        if (this.response.Data.Invoice[i].InfoInvoice.PayForBDC == BDCselect) {
          this.pIndex = i
          this.pIsGetAll = false
        }
      }
      this.resetInvoice()
    },
    async api_RIV01() {
      this.PaymentDrId = this.$route.params.id * 1;
      const body = {
        PaymentDrId: this.PaymentDrId,
        Type: "ALL",
      };
      await bookingDrivingRange.api_RIV02(body).then((response) => {
        this.listBDC = response.Data.PayFor;
        this.response = response;
      });
    },
    async api_RCOM01() {
      const body = {
        KeyGroup : "REPORT_CONFIG"
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == "IV02DR_REPORT_FILE_NAME").KeyValue
      })
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrl = `${response.Data.find(x => x.SettingCode === 'SITE_API_URL').SettingValue}/api${API_RIV02}`
      })
    },
    getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang =  localStorage.getItem('systemLanguage') || '1000000'
      this.pToken =  (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone =  localStorage.getItem('timeZone')
      this.pTimeZoneOffset =  localStorage.getItem('timeZoneOffset')
      this.pPaymentDrId = this.$route.params.id * 1
      this.api_RCOM01().then(() => {
         this.api_RCF00()
      })
    },
    getInvoice() {
      $("#iv02").telerik_ReportViewer({
          serviceUrl: REPORT_URL,
          reportSource: {
            report: this.fileName,
            parameters: {pIsGetAll: this.pIsGetAll,pInvoiceIndex: this.pIndex,pToken: this.pToken,pPaymentDrId: this.pPaymentDrId,pUrl: this.pUrl,pLang: this.pLang,pTimeZone: this.pTimeZone,pTimeZoneOffset: this.pTimeZoneOffset, pUser: JSON.parse(localStorage.getItem('userData')).userName},
          },
          viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
          scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
          scale: 1.0,
          sendEmail: { enabled: true },
        });
    },
    resetInvoice() {
      var reportViewer = $("#iv02").data("telerik_ReportViewer");
        reportViewer.reportSource({
          report: this.fileName,
          parameters: {pIsGetAll: this.pIsGetAll,pInvoiceIndex: this.pIndex,pToken: this.pToken,pPaymentDrId: this.pPaymentDrId,pUrl: this.pUrl,pLang: this.pLang,pTimeZone: this.pTimeZone,pTimeZoneOffset: this.pTimeZoneOffset, pUser: JSON.parse(localStorage.getItem('userData')).userName},
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
#iv02 {
  position: absolute;
  top: 50px;
  bottom: 10px;
  right: 0;
  overflow: hidden;
  clear: both;
  width: 85%;
}
.print {
  margin: 0 10px 0 10px;
}
.left-body {
  width: 15%;
}
.trv-report-page{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>