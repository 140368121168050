/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_BOOKING_DRIVING_RANGE,
  CREATE_BOOKING_DRIVING_RANGE,
  GET_DETAIL_BOOKING_DRIVING_RANGE,
  GET_DETAIL_BOOKING_DRIVING_RANGE_2B,
  CHECK_OUT,
  RBKD2C,
  UPDATE_BOOKING_DR_SERVICE,
  API_RBKD2D,
  API_IPM03,
  API_RPM03,
  API_DPM03,
  API_RPM04,
  API_IPM04,
  API_DPM04,
  API_UBKD9,
  API_IBKD02,
  API_UBKD02,
  API_UBKD01,
  API_ICID01,
  API_ICID02,
  API_DBKD01,
  API_DBKD02,
  API_RIV02,
  API_UPM04,
  API_RME01F,
  API_UPM03,
  API_DPM08,
  API_ICID03,
  API_UPM08,
  API_RHS02,
  API_DPM10
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListBookingDrivingRange(data) {
  return fetch(ROOT + GET_LIST_BOOKING_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_DPM10(data) {
  return fetch(ROOT + API_DPM10, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getHistory_RHS02(data) {
  return fetch(ROOT + API_RHS02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function createBookingDrivingRange(data) {
  return fetch(ROOT + CREATE_BOOKING_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailBooking(data) {
  return fetch(ROOT + GET_DETAIL_BOOKING_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailBooking2B(data) {
  return fetch(ROOT + GET_DETAIL_BOOKING_DRIVING_RANGE_2B, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getRBKD2C(data) {
  return fetch(ROOT + RBKD2C, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateBookingService(data) {
  return fetch(ROOT + UPDATE_BOOKING_DR_SERVICE, requestOptions.postBody(data)).then(handleResponse)
    .then(data => data)
}

function api_RBKD2D(data) {
  return fetch(ROOT + API_RBKD2D, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IPM03(data) {
  return fetch(ROOT + API_IPM03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPM03(data) {
  return fetch(ROOT + API_RPM03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DPM03(data) {
  return fetch(ROOT + API_DPM03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPM04(data) {
  return fetch(ROOT + API_RPM04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IPM04(data) {
  return fetch(ROOT + API_IPM04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DPM04(data) {
  return fetch(ROOT + API_DPM04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBKD9(data) {
  return fetch(ROOT + API_UBKD9, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IBKD02(data) {
  return fetch(ROOT + API_IBKD02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBKD01(data) {
  return fetch(ROOT + API_UBKD01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBKD02(data) {
  return fetch(ROOT + API_UBKD02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_ICID01(data) {
  return fetch(ROOT + API_ICID01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_ICID02(data) {
  return fetch(ROOT + API_ICID02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DBKD01(data) {
  return fetch(ROOT + API_DBKD01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DBKD02(data) {
  return fetch(ROOT + API_DBKD02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RIV02(data) {
  return fetch(ROOT + API_RIV02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UPM04(data) {
  return fetch(ROOT + API_UPM04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RME01F(data) {
  return fetch(ROOT + API_RME01F, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UPM03(data) {
  return fetch(ROOT + API_UPM03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DPM08(data) {
  return fetch(ROOT + API_DPM08, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_ICID03(data) {
  return fetch(ROOT + API_ICID03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UPM08(data) {
  return fetch(ROOT + API_UPM08, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const bookingDrivingRange = {
  getListBookingDrivingRange,
  createBookingDrivingRange,
  getDetailBooking,
  getDetailBooking2B,
  getRBKD2C,
  updateBookingService,
  api_RBKD2D,
  api_IPM03,
  api_RPM03,
  api_DPM03,
  api_RPM04,
  api_IPM04,
  api_DPM04,
  api_UBKD9,
  api_IBKD02,
  api_UBKD01,
  api_UBKD02,
  api_ICID01,
  api_ICID02,
  api_DBKD01,
  api_DBKD02,
  api_RIV02,
  api_UPM04,
  api_RME01F,
  api_UPM03,
  api_DPM08,
  api_ICID03,
  api_UPM08,
  getHistory_RHS02,
  api_DPM10
}
